import { Stock } from '@core/models/stock.model';
import { MapItem } from '@capturum/ui/api';
import { GradingCharacteristic } from '../../../features/admin/product-grading/models/grading-characteristic.model';
import { Grading } from '@core/models/grading.model';
import { GradingStation } from '@core/models/grading-station.model';

export class ReloadGradingCards {
  public static readonly type = '[Grading] Refresh grading cards grid';
}

export class SetStockPopulationData {
  public static readonly type = '[Grading] Create stock with pre-filled data';

  constructor(public stock: Partial<Stock>) {}
}

export class ResetGrading {
  public static readonly type = '[Grading] Reset Grading State';
}

export class SetGradingItem {
  public static readonly type = '[Grading] Set grading item';

  constructor(public grading: Grading) {}
}

export class SetGradingProduct {
  public static readonly type = '[Grading] Set product of current grading';

  constructor(public product: MapItem) {}
}

export class SetGradingCharacteristics {
  public static readonly type = '[Grading] Set characteristics';

  constructor(public characteristics: GradingCharacteristic[]) {}
}

export class SetActiveGradingStation {
  public static readonly type = '[Grading] Set active grading station';

  constructor(public gradingStation: GradingStation) {}
}
